import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import StaticPage from '../components/staticpage/StaticPage';
import SEO from '../components/SEO';
import RecipeCard from '../components/recipe/RecipeCard';

import '../components/recipe/recipes.css';

const RecipesPage = ({ location, data }) => {
    const recipes = data.recipes.nodes;
    const pagepathname = location.pathname;

    return (
        <StaticPage title="Recipes">
            <SEO
                title="Recipes"
                pagepathname={pagepathname}
            />

            <div className="recipes">
                {recipes.map(recipe => {
                    const { id, image, slug, basicinfo, categories, title, ratings, cuisine, recipetype, author } = recipe;

                    return (
                        <RecipeCard
                            key={`recipe-${id}`}
                            title={title}
                            id={id}
                            image={image}
                            slug={slug}
                            basicinfo={basicinfo}
                            categories={categories}
                            ratings={ratings}
                            cuisine={cuisine}
                            recipetype={recipetype}
                            author={author}
                        />
                    );
                })}
            </div>
        </StaticPage>
    );
};

export default RecipesPage;

export const query = graphql`
    query RecipesQuery {
        recipes: allSanityRecipe {
            nodes {
                id
                image {
                    _rawAsset(resolveReferences: {maxDepth: 10})
                }
                slug {
                    current
                }
                title
                categories {
                    id
                    title
                    slug {
                        current
                    }
                }
                basicinfo {
                    preptime
                    cooktime
                    cleantime
                }
                ratings {
                    taste
                    ease
                    cost
                    portion
                }
                cuisine
                recipetype
                author
                tags {
                    value
                    label
                }
            }
        }
    }
`;

RecipesPage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};
